* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
  scroll-padding-top: 150px;
}
body {
  font-family: "Maven Pro", sans-serif;
  /* font-optical-sizing: auto; */
}

::after, ::before {
  box-sizing: border-box;
}
:root {
  --brand-color: #00A3BC;
  --link-color: #0c63fd;
  --light-black: rgb(93, 87, 87);
}
p {
  margin-bottom: 8px;
}
.link-outside {
  font-size: 0;
}
   /* --browser compatabelity--
display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex;
display: flex;
-webkit-box-orient: horizontal;
-moz-box-orient: horizontal;
-webkit-box-direction: normal;
-moz-box-direction: normal;
-webkit-flex-direction: row;
-ms-flex-direction: row;
flex-direction: row; */

/* --loading suspense-- */
.loading {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
.loading-ring {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  animation: loading-ring 1.5s linear infinite;
}
@keyframes loading-ring {
  0%{
    transform: rotate(0deg);
    box-shadow: 1px 4px 2px var(--brand-color);
  }
  100%{
    transform: rotate(360deg);
    box-shadow: 1px 4px 2px var(--brand-color);
  }
}
.loading-ring::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(255, 255, 255, .3);
}
    /* --back to top-- */
.back-to-top {
  position: fixed;
  bottom: 40px;
  right: 20px;
  display: none;
  justify-content: center;
  align-items: center;
  background-color: var(--brand-color);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #fff;
  z-index: 3;
  box-shadow: 0 5px 25px rgb(1 1 1 / 20%);
}
.back-to-top.active {
  display: flex;
}
.arrow {
  border: solid #fff;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 5px;
}
.up {
  margin-top: 7px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}
  /* --language dropdown-- */
.btn {
  width: 40px;
  height: 25px;
  color: #fff;
  font-size: 14px;
  --bs-btn-padding-x: 0;
  --bs-btn-padding-y: 0;
}
.btn-secondary {
  --bs-btn-bg: var(--brand-color);
}
.dropdown-menu {
  --bs-dropdown-min-width: 6rem;
  --bs-dropdown-link-active-bg: var(--brand-color);
}
.dropdown-item {
  font-size: 15px;
}
       /* --nav bar-- */
.nav-container {
  width: 90%;
  margin: 0 auto;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  justify-content: flex-end;
}
.nav-links {
  position: fixed;
  background-color: var(--brand-color);
  color: #fff;
  padding: 4px 12px;
  border-radius: 10px;
  display: flex;
  display: -ms-flexbox;
  justify-content: space-between;
  align-items: center;
  width: 172px;
  height: fit-content;
  z-index: 3;
  margin-top: 20px;
}
.nav-links a {
  text-decoration: none;
  /* color: #727272; */
  color: #fff;
  font-size: 13px;
  font-weight: 500;
}
.nav-links a.active {
  border-bottom: 2px solid #fff;
  pointer-events: none;
}
.dropdown-flag {
  width: 22px;
}
.lang-flag {
  width: 20px;
}
/* .nav-links a:hover {
  opacity: 0.8;
} */
    /* --home page-- */
.home-container {
  /* background-color: red; */
  margin: 20px auto 0;
  width: 90%;
}
.pic-cont {
  /* background-color: green; */
  position: relative;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  margin-top: 4px;
}
.blur-cont > div > canvas {
  border-radius: 50%;
}
.pic {
  width: 180px;
  height: 180px;
  border-radius: 50%;
}
.pic-circle {
  position: absolute;
  border-radius: 50%;
  outline: 6px solid var(--brand-color);
  outline-offset: 4px;
  width: 180px;
  height: 180px;
  top: 0;
  left: 0;
}
.online-status {
  position: absolute;
  right: 10px;
  bottom: 6px;
  background-color: rgb(5, 255, 163) ;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  /* outline: 3px solid #fff; */
  box-shadow: inset 0 0 0 4px #fff;
}
.profile-info {
  margin: 20px 0;
}
.name {
  font-weight: 700;
}
.description {
  width: 90%;
}
.view-btn {
  background-color: var(--brand-color);
  font-size: 14px;
  padding: 6px 6px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  text-decoration: none;
  color: #fff;
}
.skills-container {
  margin-bottom: 30px;
}
.skills {
  display: flex;
  width: 80%;
  align-items: center;
  justify-content: space-between;
}
.skills-title {
  font-weight: 700;
  /* font-size: 17px; */
}
.skills img {
  width: 27px
}
  /* --contact-- */
.contact-email-form-cont{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 0;
}
.contact-cont {
  width: 100%;
}
.contact-title-cont {
  width: 80%;
  margin: 0 auto;
}
.contact-title {
  margin-bottom: 2px;
  text-align: center;
  font-size: 24px;
}
.contact-underline {
  margin: 0 auto 15px;
  height: 4px;
  width: 70px;
  background-color: var(--brand-color);
}
.social-contact-cont a {
  text-decoration: none;
  color: #000;
}
.social-contact {
  display: flex;
  align-items: center;
  border: 1px solid var(--brand-color);
  border-radius: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}
.social-contact img {
  width: 40px;
  margin-left: 3px;
}
.social-item {
  display: flex;
  flex-direction: column;
}
.social-item p {
  margin: 0;
  padding-left: 10px;
}
.social-item-header {
  font-weight: 600;
  font-size: 16px;
}
.social-item-connect {
  font-size: 13px;
}
.social-item-connect-arrow {
  color: var(--brand-color);
  font-weight: 600;
}
.social-contact:active {
  background-color: var(--brand-color);
  color: #fff;
}
.social-contact:active > .social-item > .social-item-connect > .social-item-connect-arrow{
  color: #fff;
  font-weight: normal;
}
/* --OR-- */
.or {
  width: fit-content;
  margin: 0 auto;
  color: var(--brand-color);
  font-weight: 600;
}
/* --reg form-- */
.reg-form {
  /* margin: 0 auto 20px; */
  display: flex;
  flex-direction: column;
  width: 100%;
}
.form-title {
  margin-bottom: 2px;
  text-align: center;
  font-size: 24px;
}
.form-title-underline {
  margin: 0 auto;
  height: 4px;
  width: 120px;
  background-color: var(--brand-color);
}
.reg-form label {
  padding: 10px 0 5px;
  font-weight: 500;
  position: relative;
}
.required {
  color: #616161;
  font-size: 23px;
  position: absolute;
}
.reg-form input {
  padding: 8px 0;
  text-indent: 6px;
  border: 1px solid var(--brand-color);
  border-radius: 10px;
  font-size: 14px;
}
.reg-form textarea {
  padding: 8px 0 70px;
  text-indent: 6px;
  border: 1px solid var(--brand-color);
  border-radius: 10px;
  font-size: 14px;
}
.reg-form input:focus, .reg-form textarea:focus {
  outline-style: solid;
  outline-color: var(--brand-color);
}
.send-btn {
  margin-top: 10px;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  background-color: var(--brand-color);
  cursor: pointer;
  border-radius: 10px;
}
.send-btn:active {
  background-color: rgb(2, 212, 2);
  border: 1px solid rgb(2, 212, 2);
}
.reg-form input[type=submit]:focus {
  outline-style: none;
  outline-color: inherit;
}
/* --works-- */
.work-blur-cont > div > canvas {
  border-radius: 10px;
}

.works-cont {
  width: 90%;
  margin: 130px auto 50px;

  /* margin-top: 150px; */
  /* display: flex;
  flex-wrap: wrap;
  gap: 25px;
  justify-content: center; */
}
.works {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  justify-content: center;
  margin-top: 20px;
}
.work {
  display: flex;
  flex-direction: column;
  /* margin-bottom: 25px;
  margin-right: 25px; */
}
.my-works {
  color: var(--brand-color);
}
.work-img-cont {
  border-radius: 10px;
  width: 320px;
  height: 275px;
}
.work-img {
  margin: 0 auto;
  width: 100%;
  border-radius: 10px;
}
.work-title {
  margin-bottom: 3px;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  color: var(--brand-color);
}
.work-url {
  width: fit-content;
  padding-right: 5px;
  padding-top: 4px;
  font-size: 16px;
  text-decoration: none;
}
.work-url:hover {
  text-decoration: underline;
}
.view-work-btn {
  border: 1px solid rgb(93, 87, 87);
  width: fit-content;
  padding: 2px 6px;
  border-radius: 10px;
  margin: 10px auto 0;
  text-decoration: none;
  color: var(--light-black);
  font-size: 14px;
}
.view-work-btn:active {
  background-color: var(--brand-color);
  color: #fff;
  border: none;
}
.view-work-btn:active > .bi-box-arrow-up-right {
  color: #fff;
}
/* --bootstrap icon-- */
.bi-box-arrow-up-right {
  font-size: 12px;
  color: var(--light-black);
}
/* --footer-- */
.footer-container {
  background-color: #000;
  width: 100%;
  height: fit-content;
  text-align: center;
  padding-top: 0.5px;
}
.logo-and-social {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 206px;
  margin: 30px auto 0;
}
.logo-blur-cont {
  width: 100px;
  height: 64px;
}
.kd-logo {
  width: 100%;
}
.social-icons {
  width: 150px;
  margin: 15px auto 0;
  display: flex;
  justify-content: space-between;
}
.icon {
  width: 25px;
}
hr {
  width: 80px;
  margin: 20px auto 8px;
  color: #fff;
  border-top: 1px solid #fff;
  opacity: 1;
}
.copyright {
  padding-bottom: 30px;
  color: #fff;
  margin-bottom: 0;
}
                          /* --catagories-- */
.catagories {
  background-color: #fff;
  position: fixed;
  width: 90%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  opacity: 0.9;
}
.catagories-link {
  display: flex;
  justify-content: space-between;
  margin: 20px auto 10px;
}
.my-works {
  padding-top: 20px;
}
.catagories a {
  text-decoration: none;
  color: rgb(93, 87, 87);
  border: 2px solid var(--brand-color);
  border-radius: 10px;
  padding: 3px 5px;
  font-size: 14px;
}
.catagories a.active {
  background-color: var(--brand-color);
  color: #fff;
  pointer-events: none;
}
.catagories-note-cont {
  background-color: rgb(232, 232, 232);
  /* text-align: center; */
  margin: 15px auto 0;
  width: fit-content;
  padding: 8px 12px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgb(1 1 1 / 20%);
  position: relative;
}
.note-light-bulb {
  position: absolute;
  top: 10px;
  left: 0;
}
.catagories-note {
  margin-left: 10px;
}
                      /* --page not found-- */
.page-not-found-cont {
  margin: 0 auto;
  width: var(--maximum-width);
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.page-not-found-cont h1 {
  font-size: 100px;
  font-weight: 600;
  color: rgb(232, 37, 37);
}
.page-not-found-cont p {
  font-size: 23px;
}
.inline {
  display: flex;
}
.inline a {
  text-decoration: none;
  color: var(--link-color);
  font-weight: 700;
  font-size: 23px;
}
            /* --Very Small Mobile Screen 
              /* --Upto 375px-- */
@media only screen and (max-width: 375px) {
  /* body {
    background-color: red;
  } */
  .nav-links {
    width: 136px;
    padding: 4px 8px;
  }
  .nav-links a {
    font-size: 10px;
  }
  .pic-cont, .pic, .pic-circle {
    width: 150px;
    height: 150px;
  }
  .online-status {
    width: 13px;
    height: 13px;
  }
  .catagories a {
    font-size: 12px;
  }
  .work-img-cont {
    width: 283px;
  }
}
            /* --Tablet-- */
        /* --721px - 960px-- */
@media only screen and (min-width: 721px) and (max-width: 960px) {
  /* body {
    background-color: rgb(205, 233, 205);
  } */
  .nav-container, .home-container, .catagories, .works-cont {
    width: 75%;
  }
  .skills {
    width: 400px;
  }
  .contact-cont, .reg-form {
    width: 80%;
  }
  .catagories a {
    font-size: 15px;
  }
  .catagories-link {
    width: 440px;
  }
  .work-img-cont {
    width: 260px;
    height: 222px;
  }
  .logo-blur-cont {
    width: 165px;
    height: 105px;
  }
  .kd-logo {
    width: 100%;
  }
  .icon {
    width: 23px;
  }
  .logo-and-social {
    /* background-color: red; */
    width: 210px;
    flex-direction: row;
    align-items: center;
    margin-top: 30px;
  }
  .social-icons {
    /* background-color: green; */
    width: fit-content;
    gap: 7px;
    flex-direction: column;
    align-items: center;
    margin: 0;
  }
  hr {
    width: 105px;
  }
}
              /* --Laptops-- */
          /* --961px - 1280px-- */
@media only screen and (min-width: 961px) {
  /* body {
    background-color: rgb(239, 155, 125);
  } */
  .nav-container, .home-container, .catagories, .works-cont {
    width: 80%;
  }
  .nav-links {
    width: 210px;
    padding: 7px 20px;
  }
  .description {
    width: 60%;
  }
  .works {
    justify-content: space-around;
  }
  .skills {
    width: 420px;
  }
  .contact-email-form-cont {
    margin-top: 60px;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
  }
  .contact-title-cont {
    margin: 0 auto 42px;
  }
  .social-contact {
    margin-bottom: 30px;
  }
  .or {
    margin: auto 0;
  }
  .contact-cont, .reg-form {
    width: 42%;
  }
  .catagories-link {
    width: 450px;
  }
  .view-btn {
    font-weight: 600;
  }
  .view-btn, .send-btn {
    color: var(--brand-color);
    background-color: #fff;
    border: 1px solid var(--brand-color);
  }
  .logo-blur-cont {
    width: 165px;
    height: 105px;
  }
  .kd-logo {
    width: 100%;
  }
  .logo-and-social {
    /* background-color: red; */
    width: 206px;
    flex-direction: row;
    align-items: center;
    margin-top: 35px;
  }
  .social-icons {
    /* background-color: green; */
    width: fit-content;
    gap: 7px;
    flex-direction: column;
    align-items: center;
    margin: 0;
  }
  .icon {
    width: 23px;
  }
  hr {
    width: 105px;
  }
      /* --hover-effect -- */
  .nav-links a:hover {
    opacity: 0.9;
  }
  .social-contact:hover {
    background-color: var(--brand-color);
    color: #fff;
  }
  .social-contact:hover > .social-item > .social-item-connect > .social-item-connect-arrow {
    color: #fff;
  }
  .view-btn:hover, .send-btn:hover {
    color: #fff;
    background-color: var(--brand-color);
  }
  .link-outside:hover {
    opacity: 0.8;
  }
  .back-to-top:hover > .arrow {
    padding: 4px;
  }
  .catagories a:hover {
    background-color: var(--brand-color);
    color: #fff;
  }
  .view-work-btn:hover {
    background-color: var(--brand-color);
    color: #fff;
    border: none;
  }
  .view-work-btn:hover > .bi {
    color: #fff;
  }
}
            /* --Extra Large Screens-- */
           /* --From 1281px and Above-- */
@media only screen and (min-width: 1281px) {
  /* body {
    background-color: rgb(236, 236, 173);
  } */
  .description {
    width: 55%;
  }
  .skills {
    width: 450px;
  }
  .works {
    justify-content: space-around;
  }
  .work-img-cont {
    width: 380px;
    height: 324px;
  }
}